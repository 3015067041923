import { lazy } from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";

const YQMV2List = lazy(() => import(/* webpackChunkName: "yqmv2" */ "pages/YQMV2/YQMV2List"));

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Outlet />,
      children: [{ path: "yqmv2/list", element: <YQMV2List /> }],
    },
  ],
  {
    basename: "/app",
  }
);

export default router;
